import React from 'react'
import styles from './Lagnateikningar.module.scss'
import MainLayout from '../../components/MainLayout'
import LagnateikningarFrom from '../../components/LagnateikningarForm'

const Lagnateikningar = props => {
  const { data } = props
  const {
    title,
    paragraph
  } = data.wordpressPage.acf
  return (
    <MainLayout topSpacing pageId={data?.wordpressPage?.wordpress_id}>
      <div className='container'>
        <div className='row'>
          <article className='col-9 pl-4 ml-1'>
            <h1 className='mb-4 pb-2'>{title}</h1>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: paragraph }} />
          </article>
        </div>
      </div>
      <LagnateikningarFrom />
    </MainLayout>
  )
}

export default Lagnateikningar
