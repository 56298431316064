import Cmp from './Lagnateikningar.js'
import { graphql } from 'gatsby'


export const pageQuery = graphql`
  query lagnateikningarPageQuery($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      wordpress_id
      acf {
        title
        paragraph
      }
    }
  }
`

export default Cmp
